import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import styles from "./Chekout.module.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CopyRight from "../CopyRight/CopyRight";
import AxiosClient from "../../Helpers/axios";
import { TiArrowBackOutline } from "react-icons/ti";
import { MdOutlinePayment } from "react-icons/md";

const CheckoutForm = () => {
  let authToken = localStorage.getItem("Authorization");
  let setProceed = authToken ? true : false;
  let navigate = useNavigate();

  useEffect(() => {
    if (setProceed) {
      getUserData();
    } else {
      navigate("/");
    }
  }, []);

  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    address: "",
    city: "",
    region: "",
  });

  const [loading, setLoading] = useState(false);
  const getUserData = async () => {
    try {
      const { data } = await AxiosClient.get("api/auth/getuser");
      setUserDetails({
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: data.phoneNumber,
        address: data.address,
        city: data.city,
        region: data.region,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const checkOutHandler = async (e) => {
    e.preventDefault();

    if (
      !userDetails.firstName ||
      !userDetails.lastName ||
      !userDetails.phoneNumber ||
      !userDetails.address ||
      !userDetails.city ||
      !userDetails.region
    ) {
      toast.error("Barcha ma'lumotlar to'ldirishi kerak", {
        autoClose: 500,
        theme: "colored",
      });
    } else {
      setLoading(true); // Start loading
      try {
        const { data } = await AxiosClient.post("api/order/create", {
          phoneNumber: userDetails.phoneNumber,
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
          address: userDetails.address,
          city: userDetails.city,
          region: userDetails.region,
        });
        toast.success("Buyurtma qabul qilindi", {
          autoClose: 500,
          theme: "colored",
        });
        window.open(data.link, "_blank");
        navigate(`/order/${data.id}`);
      } catch (error) {
        toast.error("Buyurtma berishda xatolik", {
          autoClose: 500,
          theme: "colored",
        });
      } finally {
        setLoading(false); // End loading
      }
    }
  };

  const handleOnchange = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Container
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginBottom: 10,
        }}
      >
        <Typography variant="h6" sx={{ margin: "20px 0" }}>
          Buyurtma berish
        </Typography>
        <form
          noValidate
          autoComplete="off"
          className={styles.checkout_form}
          onSubmit={checkOutHandler}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Ism"
                name="firstName"
                value={userDetails.firstName || ""}
                onChange={handleOnchange}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Familiya"
                name="lastName"
                value={userDetails.lastName || ""}
                onChange={handleOnchange}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Telefon"
                type="tel"
                name="phoneNumber"
                value={userDetails.phoneNumber || ""}
                onChange={handleOnchange}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Manzil: mahalla, ko'cha nomi, uy raqami"
                name="address"
                value={userDetails.address || ""}
                onChange={handleOnchange}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Shahar/Tuman"
                name="city"
                value={userDetails.city || ""}
                onChange={handleOnchange}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Viloyat"
                name="region"
                value={userDetails.region || ""}
                onChange={handleOnchange}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: 5,
            }}
          >
            <Link to="/cart">
              <Button
                variant="outlined"
                startIcon={<TiArrowBackOutline />}
                sx={{ marginRight: 1 }}
              >
                Ortga
              </Button>
            </Link>
            <Button
              variant="contained"
              startIcon={<MdOutlinePayment />}
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "To'lovga o'tish"
              )}
            </Button>
          </Container>
        </form>
      </Container>
      <CopyRight sx={{ mt: 8, mb: 10 }} />
    </>
  );
};

export default CheckoutForm;
