import React, { useEffect, useState } from "react";
import { Container, Typography, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { useContext } from "react";
import { ContextFunction } from "../../Context/Context";
import CategoryCard from "../../Components/CategoryCard/CategoryCard";
import Carousel from "../../Components/Carousel/Carousel";
import SearchBar from "../../Components/SearchBar/SearchBar";
import CopyRight from "../../Components/CopyRight/CopyRight";
import AxiosClient from "../../Helpers/axios";

const HomePage = () => {
  const { setCart } = useContext(ContextFunction);
  let authToken = localStorage.getItem("Authorization");
  const [categories, setCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(false);

  useEffect(() => {
    getCart();
    getCategories();
    window.scroll(0, 0);
  }, []);

  const getCart = async () => {
    if (authToken !== null) {
      try {
        const { data } = await AxiosClient.get("api/cart/fetchcart");
        setCart(data);
      } catch (error) {
        console.error("Error fetching cart:", error);
      }
    }
  };

  const getCategories = async () => {
    setLoadingCategories(true);
    try {
      const { data } = await AxiosClient.get("api/category");
      setCategories(data.categories);
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      setLoadingCategories(false);
    }
  };

  return (
    <>
      <Container
        maxWidth="xl"
        style={{
          display: "flex",
          justifyContent: "center",
          padding: 0,
          flexDirection: "column",
          marginBottom: 70,
        }}
      >
        <Box padding={1}>
          <Carousel />
        </Box>
        <Container
          style={{ marginTop: 90, display: "flex", justifyContent: "center" }}
        >
          <SearchBar />
        </Container>
        <Typography
          variant="h3"
          sx={{
            textAlign: "center",
            marginTop: 10,
            color: "#1976d2",
            fontWeight: "bold",
          }}
        >
          Katalog
        </Typography>
        <Container
          maxWidth="xl"
          style={{
            marginTop: 90,
            display: "flex",
            justifyContent: "center",
            flexGrow: 1,
            flexWrap: "wrap",
            gap: 20,
          }}
        >
          {loadingCategories ? (
            <CircularProgress />
          ) : (
            categories.map((data) => <CategoryCard data={data} key={data.id} />)
          )}
        </Container>
      </Container>
      <CopyRight sx={{ mt: 8, mb: 10 }} />
    </>
  );
};

export default HomePage;
