import React, { useEffect, useState } from "react";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import AxiosClient from "../../Helpers/axios";
import formatDate from "../../Helpers/date";
import { orderMapping } from "../../Components/Card/OrderCard/OrderCard";
import { TiArrowBackOutline } from "react-icons/ti";
import { MdOutlinePayment } from "react-icons/md";

const SingleOrder = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const [paymeLink, setPaymeLink] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchOrder();
  }, []);

  const fetchOrder = async () => {
    try {
      const { data } = await AxiosClient.get(`/api/order/${orderId}`);
      setOrder(data.order);
      setPaymeLink(data.link);
    } catch (err) {
      setError("Failed to fetch order details.");
    } finally {
      setLoading(false);
    }
  };

  const handlePaymentClick = () => {
    if (paymeLink) {
      window.open(paymeLink, "_blank");
    } else {
      alert("Payment link is not available.");
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          textAlign: "center",
          marginTop: 5,
        }}
      >
        <Typography variant="h5" color="error">
          {error}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/order")}
        >
          Back to Orders
        </Button>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        maxWidth: 600,
        margin: "0 auto",
        padding: 3,
        boxShadow: 3,
        borderRadius: 2,
      }}
    >
      <Typography
        variant="h4"
        sx={{ textAlign: "center", marginBottom: 3, color: "#1976d2" }}
      >
        Buyurtma
      </Typography>

      <Typography variant="body1">
        Buyurtma holati: <b>{orderMapping[order.status]}</b>
      </Typography>
      <Typography variant="body1">Kimga: {order.fullname}</Typography>
      <Typography variant="body1">Telefon: {order.phoneNumber}</Typography>
      <Typography variant="body1">Manzil: {order.location}</Typography>
      <Typography variant="body1">To'lov usuli: Payme</Typography>
      <Typography variant="body1">
        Yaratildi: {formatDate(order.createdAt)}
      </Typography>
      <Typography variant="body1">
        Umumiy: {order.totalPrice.toLocaleString()} so'm
      </Typography>
      <Typography variant="body1">
        To'landi: {order.paidPrice.toLocaleString()} so'm
      </Typography>

      <Box sx={{ marginTop: 2 }}>
        <Typography variant="body1">Mahsulotlar</Typography>
        {order.products.map((product, index) => (
          <Box key={product._id} sx={{ marginLeft: 2 }}>
            <Typography variant="body2">
              {index + 1}. {product.productName} -{" "}
              {(product.price * product.quantity).toLocaleString()} so'm,{" "}
              {product.quantity} dona
            </Typography>
          </Box>
        ))}
      </Box>

      <Box sx={{ textAlign: "center", marginTop: 3 }}>
        <Button
          variant="outlined"
          onClick={() => navigate("/order")}
          sx={{ marginRight: 1 }}
          startIcon={<TiArrowBackOutline />}
        >
          Ortga
        </Button>
        {order.status === "PENDING" && (
          <Button
            variant="contained"
            color="primary"
            onClick={handlePaymentClick}
            startIcon={<MdOutlinePayment />}
          >
            To'lovga o'tish
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default SingleOrder;
