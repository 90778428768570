import "./Productsimilar.css";
import React, { useEffect, useState, useContext } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Tooltip,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Chip,
  Rating,
  ButtonGroup,
  Skeleton,
} from "@mui/material";
import { MdAddShoppingCart } from "react-icons/md";
import {
  AiFillHeart,
  AiFillCloseCircle,
  AiOutlineLogin,
  AiOutlineShareAlt,
} from "react-icons/ai";
import { toast } from "react-toastify";
import { ContextFunction } from "../../Context/Context";
import ProductReview from "../../Components/Review/ProductReview";
import ProductCard from "../../Components/Card/ProductCard/ProductCard";
import { Transition } from "../../Constants/Constant";
import CopyRight from "../../Components/CopyRight/CopyRight";
import AxiosClient from "../../Helpers/axios";
import ProductCarousel from "../../Components/Carousel/ProductCarousel";
import Zoom from "react-medium-image-zoom";

const ProductDetail = () => {
  const { cart, setCart, wishlistData, setWishlistData } =
    useContext(ContextFunction);
  const navigate = useNavigate();
  const [openAlert, setOpenAlert] = useState(false);
  const { slug } = useParams();
  const [product, setProduct] = useState(null);
  const [productImages, setProductImages] = useState([]);
  const [similarProduct, setSimilarProduct] = useState([]);
  const [productQuantity, setProductQuantity] = useState(1);
  const [loading, setLoading] = useState(true);

  let authToken = localStorage.getItem("Authorization");
  let setProceed = authToken ? true : false;

  useEffect(() => {
    getProduct();
    getSimilarProducts();
    window.scroll(0, 0);
  }, [slug]);

  const getProduct = async () => {
    try {
      const { data } = await AxiosClient.get(
        `api/product/fetchproduct-by-slug/${slug}`
      );
      setProduct(data);
      setProductImages(data.images);
      setLoading(false);
    } catch (error) {
      toast.error("Mahsulot topilmadi", {
        autoClose: 500,
        theme: "colored",
      });
    }
  };

  const addToCart = async (product) => {
    if (setProceed) {
      try {
        const { data } = await AxiosClient.post("api/cart/addcart", {
          _id: product._id,
          quantity: productQuantity,
        });
        setCart(data);
        setCart([...cart, product]);
        toast.success("Savatchaga qo'shildi", {
          autoClose: 500,
          theme: "colored",
        });
        navigate("/cart");
      } catch (error) {
        let message = error.response.data.msg;
        if (message === "Product already in a cart") {
          message = "Mahsulot savatchada mavjud";
        }

        toast.error(message, {
          autoClose: 500,
          theme: "colored",
        });
      }
    } else {
      setOpenAlert(true);
    }
  };

  const addToWhishList = async (product) => {
    if (setProceed) {
      try {
        const { data } = await AxiosClient.post("api/wishlist/addwishlist", {
          _id: product._id,
        });
        setWishlistData(data);
        setWishlistData([...wishlistData, product]);
        toast.success("Sevimlilarga ro'yhatiga qo'shildi", {
          autoClose: 500,
          theme: "colored",
        });
      } catch (error) {
        let message = error.response.data.msg;
        if (message === "Product already in a wishlist") {
          message = "Mahsulot Sevimlilar ro'yhatida mavjuda";
        }
        toast.error(message, {
          autoClose: 500,
          theme: "colored",
        });
      }
    } else {
      setOpenAlert(true);
    }
  };

  const shareProduct = (product) => {
    const data = {
      text: product.name,
      title: "LuxBugs",
      url: `${process.env.REACT_APP_FRONTEND}product/${product.slug}`,
    };
    if (navigator.canShare && navigator.canShare(data)) {
      navigator.share(data);
    } else {
      toast.error("Browser qo'llab quvvatlamaydi", {
        autoClose: 500,
        theme: "colored",
      });
    }
  };
  const getSimilarProducts = async () => {
    try {
      const { data } = await AxiosClient.post(
        "api/product/fetchproduct-by-category",
        {
          productSlug: slug,
        }
      );
      setSimilarProduct(data.products);
    } catch (error) {
      console.error(error);
    }
  };

  let data = [];

  const increaseQuantity = () => {
    setProductQuantity((prev) => prev + 1);
  };
  const decreaseQuantity = () => {
    setProductQuantity((prev) => prev - 1);
    if (productQuantity <= 1) {
      setProductQuantity(1);
    }
  };
  return (
    <>
      <Container maxWidth="xl">
        <Dialog
          open={openAlert}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setOpenAlert(false)}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent sx={{ width: { xs: 280, md: 350, xl: 400 } }}>
            <DialogContentText
              style={{ textAlign: "center" }}
              id="alert-dialog-slide-description"
            >
              Davom ettirish uchun sahifangizga kiring
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{ display: "flex", justifyContent: "space-evenly" }}
          >
            <Link to="/login">
              {" "}
              <Button
                variant="contained"
                endIcon={<AiOutlineLogin />}
                color="primary"
              >
                Kirish
              </Button>
            </Link>
            <Button
              variant="contained"
              color="error"
              onClick={() => setOpenAlert(false)}
              endIcon={<AiFillCloseCircle />}
            >
              Ortga
            </Button>
          </DialogActions>
        </Dialog>

        <main className="main-content">
          {loading ? (
            <Skeleton variant="rectangular" height={400} />
          ) : (
            <Zoom>
              <div className="product-image">
                <div className="detail-img-box">
                  <img
                    alt={product.name}
                    src={product.url}
                    className="detail-img"
                  />
                  <br />
                </div>
              </div>
            </Zoom>
          )}
          {loading ? (
            <section
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <Skeleton variant="rectangular" height={200} width="200px" />
              <Skeleton variant="text" height={400} width={700} />
            </section>
          ) : (
            <section className="product-details">
              <Typography variant="h4">{product.name}</Typography>

              <Typography>{product.description}</Typography>
              <Typography>
                <div className="chip">
                  {data.map((item, index) => (
                    <Chip label={item} key={index} variant="outlined" />
                  ))}
                </div>
              </Typography>
              <div style={{ display: "flex", gap: 20 }}>
                <Typography variant="h6" color="primary">
                  {product.price.toLocaleString()} so'm
                </Typography>
              </div>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  "& > *": {
                    m: 1,
                  },
                }}
              >
                <ButtonGroup
                  variant="outlined"
                  aria-label="outlined button group"
                >
                  <Button onClick={decreaseQuantity}>-</Button>
                  <Button>{productQuantity}</Button>
                  <Button onClick={increaseQuantity}>+</Button>
                </ButtonGroup>
              </Box>
              <Rating
                name="read-only"
                value={Math.round(product.rating)}
                readOnly
                precision={0.5}
              />
              <div style={{ display: "flex" }}>
                <Tooltip title="Savatchaga qo'shish">
                  <Button
                    variant="contained"
                    className="all-btn"
                    startIcon={<MdAddShoppingCart />}
                    onClick={() => addToCart(product)}
                  >
                    Qo'shish
                  </Button>
                </Tooltip>
                <Tooltip title="Istaklarga qo'shish">
                  <Button
                    style={{ marginLeft: 10 }}
                    size="small"
                    variant="contained"
                    className="all-btn"
                    onClick={() => addToWhishList(product)}
                  >
                    {<AiFillHeart fontSize={21} />}
                  </Button>
                </Tooltip>
                <Tooltip title="Do'stlarga yuborish">
                  <Button
                    style={{ marginLeft: 10 }}
                    variant="contained"
                    className="all-btn"
                    startIcon={<AiOutlineShareAlt />}
                    onClick={() => shareProduct(product)}
                  ></Button>
                </Tooltip>
              </div>
            </section>
          )}
        </main>
        {productImages.length > 0 && (
          <Typography component="h4" variant="h4" sx={{ marginTop: 5 }}>
            Rasmlar
          </Typography>
        )}
        <ProductCarousel products={productImages} />
        {product && (
          <ProductReview
            setProceed={setProceed}
            authToken={authToken}
            id={product?.id}
            setOpenAlert={setOpenAlert}
          />
        )}

        <Typography
          sx={{
            marginTop: 10,
            marginBottom: 5,
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          O'xshash mahsulotlar
        </Typography>
        <Box>
          <Box
            className="similarProduct"
            sx={{ display: "flex", overflowX: "auto", marginBottom: 10 }}
          >
            {similarProduct
              .filter((prod) => prod.slug !== slug)
              .map((prod) => (
                <Link to={`/product/${prod.slug}`} key={prod._id}>
                  <ProductCard prod={prod} />
                </Link>
              ))}
          </Box>
        </Box>
      </Container>
      <CopyRight sx={{ mt: 8, mb: 10 }} />
    </>
  );
};

export default ProductDetail;
