import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  ListItem,
  FormControl,
  ListItemText,
  InputLabel,
  List,
  Select,
  MenuItem,
} from "@mui/material";
import { toast } from "react-toastify";
import CopyRight from "../../Components/CopyRight/CopyRight";
import { IoMdSave } from "react-icons/io";
import AxiosClient from "../../Helpers/axios";
import formatDate from "../../Helpers/date";
import { IoChevronBackOutline } from "react-icons/io5";

const orderStatuses = ["CANCELLED", "PENDING", "PAID", "DELIVERED"];

const SingleOrder = () => {
  const [order, setOrder] = useState({
    fullname: "",
    phoneNumber: "",
    location: "",
    paymentMethod: "",
    products: [],
    orderHistory: [],
    totalPrice: 0,
    paidPrice: 0,
  });
  const [status, setStatus] = useState("PENDING");
  const { id } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    getSingleOrder();
    window.scroll(0, 0);
  }, []);

  const handleStatusChange = (event) => setStatus(event.target.value);

  const getSingleOrder = async () => {
    const { data } = await AxiosClient.get(`api/admin/orders/${id}`);

    setOrder({
      fullname: data.fullname,
      phoneNumber: data.phoneNumber,
      location: data.location,
      paymentMethod: data.paymentMethod,
      products: data.products,
      orderHistory: data.history,
      totalPrice: data.totalPrice,
      paidPrice: data.paidPrice,
    });
    setStatus(data.status);
  };
  const handleOnchange = (e) => {
    setOrder({ ...order, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await AxiosClient.patch(`api/admin/orders/${id}`, {
        status,
      });
      toast.success("Order updated successfully", { autoClose: 500 });
      navigate("/admin/home?tab=order");
    } catch (error) {
      toast.error("Something went wrong", { autoClose: 500 });
    }
  };

  const backHandler = () => navigate("/admin/home?tab=order");

  return (
    <>
      <Container sx={{ width: "100%", marginBottom: 5 }}>
        <div>
          <Typography variant="h4">Order</Typography>
        </div>
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          style={{ marginTop: 30 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Full name"
                name="name"
                value={order.fullname}
                onChange={handleOnchange}
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
                inputProps={{ readOnly: true }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginTop: 1 }}>
            <Grid item xs={12}>
              <TextField
                label="Phone number"
                name="phoneNumber"
                value={order.phoneNumber}
                onChange={handleOnchange}
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
                inputProps={{ readOnly: true }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginTop: 1 }}>
            <Grid item xs={12}>
              <TextField
                label="Payment method"
                name="paymentMethod"
                value={order.paymentMethod}
                onChange={handleOnchange}
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
                inputProps={{ readOnly: true }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginTop: 1 }}>
            <Grid item xs={12}>
              <TextField
                label="Location"
                name="location"
                value={order.location}
                onChange={handleOnchange}
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
                inputProps={{ readOnly: true }}
              />
            </Grid>
          </Grid>

          <Box sx={{ marginTop: 1 }}>
            <Typography variant="h5">Products</Typography>
            <List>
              {order.products.map((product, index) => (
                <ListItem key={product._id}>
                  <ListItemText
                    primary={`${index + 1}. ${
                      product.productName
                    } - ${product.price.toLocaleString()} so'm`}
                    secondary={`Quantity: ${product.quantity}`}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
          <Box sx={{ marginTop: 1 }}>
            <Typography variant="h5">Order history</Typography>
            <List>
              {order.orderHistory.map((st, index) => (
                <ListItem key={st._id}>
                  <ListItemText
                    primary={`${index + 1}. ${st.status} - ${formatDate(
                      st.createdAt
                    )}`}
                  />
                </ListItem>
              ))}
            </List>
          </Box>

          <Grid item xs={12} sx={{ marginTop: 1 }}>
            <Typography variant="h5">Total price</Typography>
            <Typography variant="body1">
              {order.totalPrice.toLocaleString()} so'm
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 1 }}>
            <Typography variant="h5">Paid price</Typography>
            <Typography variant="body1">
              {order.paidPrice.toLocaleString()} so'm
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ marginTop: 2 }}>
            <FormControl>
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                labelId="status-label"
                id="status"
                value={status}
                label="Status"
                onChange={handleStatusChange}
                autoWidth
              >
                {orderStatuses.map((st) => {
                  return (
                    <MenuItem key={st} value={st}>
                      {st}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Container
            sx={{
              textAlign: "right",
              marginTop: 10,
            }}
          >
            <Button
              variant="outlined"
              startIcon={<IoChevronBackOutline />}
              sx={{ marginRight: 1 }}
              onClick={backHandler}
            >
              Back
            </Button>
            <Button variant="contained" startIcon={<IoMdSave />} type="submit">
              Change status
            </Button>
          </Container>
        </form>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            margin: "25px 0",
            width: "100%",
          }}
        ></Box>
      </Container>
      <CopyRight sx={{ mt: 8, mb: 10 }} />
    </>
  );
};

export default SingleOrder;
