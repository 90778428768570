import React from "react";
import { Box, Typography, Paper, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import formatDate from "../../../Helpers/date";

export const orderMapping = {
  PENDING: "To'lov qilinishi kutilmoqda",
  PAID: "To'landi",
  DELIVERED: "Yetkazildi",
  CANCELLED: "Bekor qilindi",
};

const OrderCard = ({ order }) => {
  const navigate = useNavigate();

  const handleViewOrderDetails = () => {
    navigate(`/order/${order._id}`);
  };

  return (
    <Paper
      elevation={3}
      sx={{
        padding: 2,
        margin: { xs: 1, sm: 2 },
        width: "100%",
      }}
    >
      <Typography variant="body1">Kimga: {order.fullname}</Typography>
      <Typography variant="body1">Telefon: {order.phoneNumber}</Typography>
      <Typography variant="body1">To'lov usuli: Payme</Typography>
      <Typography variant="body1">
        Yaratildi: {formatDate(order.createdAt)}
      </Typography>
      <Typography variant="body1">
        Umumiy: {order.totalPrice.toLocaleString()} so'm
      </Typography>
      <Typography variant="body1">
        Buyurtma holati: <b>{orderMapping[order.status]}</b>
      </Typography>
      <Box sx={{ textAlign: "left", marginTop: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleViewOrderDetails}
        >
          Buyurtma tafsilotlari
        </Button>
      </Box>
    </Paper>
  );
};

export default OrderCard;
