import {
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
} from "../Assets/Images/Image";

const data = [
  {
    img: image1,
    name: "Product 2",
  },
  {
    img: image2,
    name: "Product 2",
  },
  {
    img: image3,
    name: "Product 3",
  },
  {
    img: image4,
    name: "Product 4",
  },
  {
    img: image5,
    name: "Product 5",
  },
  {
    img: image6,
    name: "Product 6",
  },
  {
    img: image7,
    name: "Product 7",
  },
  {
    img: image8,
    name: "Product 8",
  },
  {
    img: image9,
    name: "Product 9",
  },
  {
    img: image10,
    name: "Product 10",
  },
];
for (let i = data.length - 1; i > 0; i--) {
  const j = Math.floor(Math.random() * (i + 1));
  [data[i], data[j]] = [data[j], data[i]];
}

export default data;
