import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { IoBagCheckOutline } from "react-icons/io5";
import paymeImage from "../../Assets/payme.png";

const OrderSummary = ({ proceedToCheckout, total, shippingCoast }) => {
  return (
    <Card sx={{ width: { xs: 450, sm: 550, md: 550, lg: 700 } }} elevation={15}>
      <CardContent>
        <Typography variant="div" component="h1">
          {" "}
          Buyurtma
        </Typography>
        <Typography variant="subtitle2">
          <hr />
        </Typography>
        <Grid sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 2,
            }}
          >
            <Typography variant="body1" component="div" color="primary">
              Mahsulotlar
            </Typography>
            <Typography variant="h6" component="div" color="primary">
              {Number(total - shippingCoast).toLocaleString()} so'm
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 2,
            }}
          >
            <Typography variant="body1" component="div" color="primary">
              Yetkazib berish
            </Typography>
            <Typography variant="h6" component="div" color="primary">
              {shippingCoast.toLocaleString()} so'm
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 2,
            }}
          >
            <Typography variant="body1" component="div" color="primary">
              Umumiy
            </Typography>
            <Typography variant="h6" component="div" color="primary">
              {total.toLocaleString()} so'm
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <Typography variant="body1" component="div" color="primary">
                To'lov usuli
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Box
                component="img"
                src={paymeImage}
                alt="Payme logo"
                sx={{
                  width: "auto",
                  height: 24,
                }}
              />
            </Box>
          </Box>
        </Grid>
      </CardContent>
      <CardActions
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Button
          variant="contained"
          size="large"
          endIcon={<IoBagCheckOutline />}
          color="primary"
          onClick={proceedToCheckout}
        >
          Sotib olish
        </Button>
      </CardActions>
    </Card>
  );
};

export default OrderSummary;
