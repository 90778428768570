import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./Pages/Home/HomePage";
import Login from "./Auth/Login/Login";
import Register from "./Auth/Register/Register";
import Cart from "./Pages/Cart/Cart";
import ProductDetail from "./Pages/Product/ProductDetail";
import SingleCategory from "./Pages/Category/SingleCategory";
import AdminSingleCategory from "./Admin/Pages/AdminSingleCategory";
import AdminSingleOrder from "./Admin/Pages/AdminSingleOrder";
import MobileNavigation from "./Navigation/MobileNavigation";
import DesktopNavigation from "./Navigation/DesktopNavigation";
import Wishlist from "./Pages/WishList/Wishlist";
import Order from "./Pages/Order/Order";
import SingleOrder from "./Pages/Order/SinglieOrder";
import { Flip, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CheckoutForm from "./Components/Checkout/CheckoutForm";
import UpdateDetails from "./Pages/UpdateUser/UpdateDetails";
import ForgotPasswordForm from "./Auth/ForgotPassword/ForgotPasswordForm";
import AddNewPassword from "./Auth/ForgotPassword/AddNewPassword";
import AdminHomePage from "./Admin/Pages/AdminHomePage";
import SingleUserPage from "./Admin/Pages/SingleUserPage";
import SingleProduct from "./Admin/Pages/SingleProduct";
import PageNotFound from "./Pages/404/PageNotFound";

function App() {
  return (
    <>
      <ToastContainer
        toastClassName="toastContainerBox"
        transition={Flip}
        position="top-center"
      />
      <Router>
        <DesktopNavigation />
        <div className="margin">
          <Routes>
            {/*User Routes  */}
            <Route path="/" index element={<HomePage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/product/:slug" element={<ProductDetail />} />
            <Route path="/category/:categoryId" element={<SingleCategory />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/wishlist" element={<Wishlist />} />
            <Route path="/order" element={<Order />} />
            <Route path="/order/:orderId" element={<SingleOrder />} />
            <Route path="/checkout" element={<CheckoutForm />} />
            <Route path="/profile" element={<UpdateDetails />} />
            <Route path="/forgotpassword" element={<ForgotPasswordForm />} />
            <Route path="/user/reset/:id/:token" element={<AddNewPassword />} />

            {/* Admin Routes */}
            <Route path="/admin/home" element={<AdminHomePage />} />
            <Route path="/admin/home/user/:id" element={<SingleUserPage />} />
            <Route path="/admin/home/product/:id" element={<SingleProduct />} />
            <Route
              path="/admin/home/category/:id"
              element={<AdminSingleCategory />}
            />
            <Route
              path="/admin/home/order/:id"
              element={<AdminSingleOrder />}
            />

            {/* Page not found */}
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </div>
        <MobileNavigation />
      </Router>
    </>
  );
}
export default App;
