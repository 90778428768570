export let image1 = "https://cdn.nodirbek.uz/ecommerce/banner/1.jpg";
export let image2 = "https://cdn.nodirbek.uz/ecommerce/banner/2.jpg";
export let image3 = "https://cdn.nodirbek.uz/ecommerce/banner/3.jpg";
export let image4 = "https://cdn.nodirbek.uz/ecommerce/banner/4.jpg";
export let image5 = "https://cdn.nodirbek.uz/ecommerce/banner/5.jpg";
export let image6 = "https://cdn.nodirbek.uz/ecommerce/banner/6.jpg";
export let image7 = "https://cdn.nodirbek.uz/ecommerce/banner/7.jpg";
export let image8 = "https://cdn.nodirbek.uz/ecommerce/banner/8.jpg";
export let image9 = "https://cdn.nodirbek.uz/ecommerce/banner/9.jpg";
export let image10 = "https://cdn.nodirbek.uz/ecommerce/banner/10.jpg";
export let customerReview =
  "https://res.cloudinary.com/dxguqzge7/image/upload/v1682838911/Review_bm6chw.jpg";
export let EmptyCart =
  "https://res.cloudinary.com/dxguqzge7/image/upload/v1682838909/Cart_bk4xgl.jpg";
